import { SongVersionItem } from "@/models/frontend-only/SongVersion";

export async function getSongVersions(songNumber: string) {
  const beta = useBetaStore();
  if (!beta.features.recordingFocusLayout) {
    return null;
  }
  const data = await tokenFetch<SongVersionItem[]>(
    `${AppConstants.CHART_BASE_URL}/recordedVersions.json`,
    true,
  );
  if (!data) {
    throw new Error("Error fetching data");
  }

  const songVersion =
    data.find((d) => d.SongID.toString() === songNumber) || null;
  return songVersion;
}
